<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1>Website</h1>
        <b><u></u></b>
        <p>Als je op zoek bent naar een website, ben je aan het juiste adres. Of je nu een strakke zakelijke site, een
          creatieve portfolio of een e-commerceplatform nodig hebt, ik sta klaar om jouw visie tot leven te brengen. De
          prijzen starten vanaf €500, met een jaarlijkse hostingkost van slechts €40. Laat mij je helpen om jouw online
          aanwezigheid te versterken en je verhaal met de wereld te delen. Neem vandaag nog contact op en samen bouwen we
          aan jouw online succes!</p>
      </div>
      <div class="col">
        <img src="pexels-format-1029757.jpg" class="img-fluid" alt="home">
      </div>
    </div>
  </div>
</template>
