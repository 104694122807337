<template>
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="container">
            <div class="row">
              <h1>Contact</h1>
              <p>
                <i class="pi pi-at"></i>
                &nbsp;
                <a href="mailto:jacobs.jordi@gmail.com">jacobs.jordi@gmail.com</a>
              </p>
              <p>
                <i class="pi pi-phone"></i>
                &nbsp;
                <a href="tel:+32471537419">+32 471 537 419</a>
              </p>
            </div>
            <div class="row">
              <h1>People</h1>
              <ul>
                <li>
                  Jordi Jacobs
                  <span>
                    &nbsp;
                    <a href="https://www.linkedin.com/in/jordi-jacobs-591b719a/">Linkedin</a>
                  </span>
                  <span>
                    &nbsp;
                    <a href="https://jordijacobs.be">Website</a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <img src="pexels-element-digital-1550337.jpg" class="img-fluid" alt="contact us">
        </div>
      </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      phoneNumber: '',
    };
  },
};
</script>
