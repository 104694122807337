import { createApp } from 'vue'
import App from './App.vue'
import { createWebHistory, createRouter } from "vue-router";

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import HomeView from "./views/HomeView";
import WebsiteView from "./views/WebsiteView";
import SoftwareView from "./views/SoftwareView";
import ConsultancyView from "./views/ConsultancyView";
import ContactView from "./views/ContactView";

const routes = [
  {
    path: "/website",
    name: "Website",
    component: WebsiteView,
  },
  {
    path: "/software",
    name: "Software",
    component: SoftwareView,
  },
  {
    path: "/consultancy",
    name: "Consultancy",
    component: ConsultancyView,
  },
  {
    path: "/Contact",
    name: "Contact",
    component: ContactView,
  },
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

createApp(App).use(router).mount('#app')
