<template>
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <div class="container-fluid">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link" @click="this.$router.push('/')"><span><i class="pi pi-home">&nbsp;</i></span>Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="this.$router.push('website')"><span><i
                    class="pi pi-globe">&nbsp;</i></span>Website</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="this.$router.push('software')"><span><i
                    class="pi pi-desktop">&nbsp;</i></span>Software</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="this.$router.push('consultancy')"><span><i
                    class="pi pi-chart-line">&nbsp;</i></span>Consultancy</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" @click="this.$router.push('contact')"><span><i
                    class="pi pi-phone">&nbsp;</i></span>Contact</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  components: {
  },
  data() {
    return {
    };
  },
}
</script>
