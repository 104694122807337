<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1>Consultancy</h1>
        <p>Ik help met jouw project en/of product. Zie contact voor meer informatie.</p>
      </div>
      <div class="col">
        <img src="pexels-cottonbro-studio-5474285.jpg" class="img-fluid" alt="home">
      </div>
    </div>
  </div>
</template>
