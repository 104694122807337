<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <table>
          <tr>
            <td>
              <a class="nav-link" @click="this.$router.push('website')"><span><i class="pi pi-globe">&nbsp;</i></span>Wij
                maken <b>uw website</b></a>
            </td>
          </tr>
          <tr>
            <td>
              <a class="nav-link" @click="this.$router.push('software')"><span><i
                    class="pi pi-desktop">&nbsp;</i></span>Wij maken <b>uw software</b></a>
            </td>
          </tr>
          <tr>
            <td>
              <a class="nav-link" @click="this.$router.push('consultancy')"><span><i
                    class="pi pi-chart-line">&nbsp;</i></span>Wij werken voor <b>uw bedrijf</b></a>
            </td>
          </tr>
        </table>
      </div>
      <div class="col">
        <img src="pexels-maria-orlova-4906249.jpg" class="img-fluid" alt="home">
      </div>
    </div>
  </div>
</template>
