<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h1>Software</h1>
        <p>Bent u op zoek naar software die perfect aansluit op <b><u>uw bedrijfsbehoeften</u></b>, compleet met
          <b><u>moeiteloze hosting</u></b>? Zoek niet verder! Software <b><u>op maat gemaakt</u></b> die precies aan uw
          wensen voldoet en bij <b><u>uw bedrijf</u></b> past. Hosting afhankelijk van de situatie en <b><u>eenvoudig te
              verhuizen</u></b>. Zodat uw software altijd beschikbaar is waar u het nodig heeft. Bespaar tijd en moeite
          met <b><u>automatische release</u></b>. Indien nodig wordt uw software <b><u>regelmatig bijgewerkt</u></b> en
          verbeterd zonder gedoe. Monitoring van de resources en prestaties, voor een <b><u>vlekkeloze
              gebruikerservaring</u></b>. Voorkom kostbaar dataverlies met <b><u>automatische back-ups</u></b>. Uw
          gegevens worden veilig bewaard, zodat u altijd gemoedsrust heeft. <b><u>Uitgebreide documentatie</u></b> zodat
          iedereen begrijpt hoe het werkt. Dit maakt samenwerken en onderhoud eenvoudiger dan ooit. <b><u>Uw succes is
            topprioriteit.</u></b></p>
    </div>
    <div class="col">
      <img src="scott-graham-5fNmWej4tAA-unsplash.jpg" class="img-fluid" alt="home">
    </div>
  </div>
</div></template>
